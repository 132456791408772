import { CSSProperties } from 'react';

import { styledComponents } from '../StyledComponents';
import { ResponsiveProperty, getResponsiveProperty } from './responsive';

export type ResponsiveType<T extends keyof CSSProperties> = ResponsiveProperty<
  CSSProperties[T]
>;

export type ResponsiveProps<T extends keyof CSSProperties> = {
  [key in T]?: ResponsiveType<T>;
};

export const responsiveHelper = (cssProperty: keyof CSSProperties) =>
  styledComponents.css<ResponsiveProps<typeof cssProperty>>(
    ({ theme, [cssProperty]: prop, ...props }) => {
      // const prop = props[cssProperty];
      return styledComponents.css`
        ${getResponsiveProperty(cssProperty as any, prop, theme)};
      `;
    }
  );
