import * as SwitchPrimitive from '@radix-ui/react-switch';
import { rgba } from 'polished';
import { styled } from '../..';
import { Reveal } from '../Reveal';
import { Typography } from '../Typography';

export interface SwitchProps extends SwitchPrimitive.SwitchProps {
  children?: React.ReactNode;
}

export const Switch: React.FC<React.PropsWithChildren<SwitchProps>> = ({ children, ...props }) => {
  return (
    <Reveal>
      <Root>
        <StyledSwitch hasChildren={!!children} {...props}>
          <StyledThumb />
        </StyledSwitch>

        <Caption>
          <Typography.Caption size="L" textAlign="start">
            {children}
          </Typography.Caption>
        </Caption>
      </Root>
    </Reveal>
  );
};

const Root = styled.label`
  appearance: none;
  border: none;
  display: flex;
`;

const StyledSwitch = styled(({ hasChildren, ...props }) => (
  <SwitchPrimitive.Root {...props} />
))<{ hasChildren: boolean }>`
  appearance: none;
  border: none;
  all: 'unset';
  width: 42px;
  height: 25px;
  background-color: ${({ theme }) => rgba(theme.foreground, 0.2)};
  border-radius: 9999px;
  position: relative;
  margin-right: ${({ hasChildren }) => (hasChildren ? 16 : 0)}px;
  transition: 100ms;

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.foreground};
  }
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: 0px 0px 4px 2px ${({ theme }) => rgba(theme.foreground, 0.1)};

  &[data-state='checked'] {
    transform: translateX(19px);
  }
`;

export const Caption = styled.div`
  margin-top: 3px;
`;
