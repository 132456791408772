import React from 'react';
import { rgba } from 'polished';

import { styled } from '../../StyledComponents';
import { TypographyProps, typographyHelper } from './utils/typographyHelper';
import {
  SpacingTypographyProps,
  spacingTypographyHelper,
} from './utils/spacingTypographyHelper';

export interface MarkProps extends TypographyProps, SpacingTypographyProps {
  children?: React.ReactNode;
  className?: string;
  variant?: 'highlight' | 'transparent';
}

export const Mark = React.forwardRef<HTMLElement, MarkProps>((props, ref) => {
  const { children } = props;
  return (
    <Root ref={ref} {...props}>
      {children}
    </Root>
  );
});

const RootComponent = React.forwardRef<HTMLElement, MarkProps>((props, ref) => {
  const { className, children } = props;
  return (
    <mark ref={ref} className={className}>
      {children}
    </mark>
  );
});

const RootWithTheme = styled(RootComponent)(({ theme }) => ({
  color: theme.foreground,
}));

const Root = styled(RootWithTheme)`
  ${typographyHelper};
  ${spacingTypographyHelper};
  color: ${({ theme }) => theme.foreground};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 1px;
  margin: -2px;
  background: ${({ variant }) =>
    variant === 'transparent' ? 'transparent' : rgba(255, 230, 0, 0.5)};
  border: ${({ variant }) => (variant === 'transparent' ? 0 : 1)}px solid
    ${({ theme, variant }) =>
      rgba(
        theme.foreground,
        variant === 'transparent'
          ? theme.dark
            ? 0.25
            : 0.25
          : theme.dark
          ? 0.5
          : 0.1
      )};
  transition: 0.1s;

  &:hover,
  &:focus {
    background: ${({ theme, variant }) =>
      variant === 'transparent' ? rgba(theme.foreground, 0.2) : undefined};
  }

  &:focus {
    padding: 0px 2px;
    margin: -3px;
  }

  &:active {
    padding: 0px 3px;
    margin: -4px;
    transition: 0.05s;
    background: ${({ theme, variant }) =>
      variant === 'transparent'
        ? rgba(theme.foreground, 0.1)
        : rgba(255, 230, 0, 0.25)};
  }
`;
