import { rgba } from 'polished';
import { CSSProperties } from 'styled-components';

export const defaultBreakPoints = {
  phoneMaxWidth: 680,
  tabletMaxWidth: 990,
};

export const headlineTypography = {
  fontFamily: "'Source Sans Pro'",
};

export const typography = {
  h1: {
    ...headlineTypography,
    fontFamily: 'Harmony',
    marginBottom: 24,
    marginTop: 16,
    letterSpacing: '0.025em',
    fontWeight: 'normal',
    fontSize: '2.8rem',
    lineHeight: '1.3em',
  } as CSSProperties,
  h2: {
    ...headlineTypography,
    fontSize: '2rem',
    fontFamily: 'Harmony',
    fontWeight: 'normal',
  } as CSSProperties,
  h3: {
    ...headlineTypography,
    fontFamily: 'Harmony',
    fontWeight: 'normal',
    fontSize: '1.5rem',
  } as CSSProperties,
  h4: {
    ...headlineTypography,
  } as CSSProperties,
  h5: {
    ...headlineTypography,
  } as CSSProperties,
  h6: {
    ...headlineTypography,
  } as CSSProperties,
  paragraph: {
    default: {
      fontFamily: "'Source Sans Pro', sans",
      fontWeight: 'normal',
      fontSize: '0.875rem',
      lineHeight: '2em',
      maxWidth: 640,
      marginTop: 24,
      marginBottom: 24,
    } as CSSProperties,
    L: {
      fontSize: '1rem',
    } as CSSProperties,
    M: {
      fontSize: '0.875rem',
    } as CSSProperties,
  },
  textParagraph: {
    default: {
      fontFamily: '"Spectral", serif',
      fontSize: '1.2rem',
      lineHeight: '1.8em',
      fontWeight: 500,
      maxWidth: 720,
      letterSpacing: '-0.02em',
    } as CSSProperties,
    L: {
      fontSize: '1.2rem',
    } as CSSProperties,
    M: {
      fontSize: '1.2rem',
    } as CSSProperties,
  },
  button: {
    label: {
      default: {
        fontFamily: "'Source Sans Pro', sans",
        lineHeight: '1em',
      },
      M: {
        fontSize: '1rem',
      } as CSSProperties,
      S: {
        fontSize: '0.8rem',
        // fontSize: '0.75rem',
      } as CSSProperties,
    },
  },
  tag: {
    label: {
      default: {
        fontFamily: "'Source Sans Pro', sans",
        lineHeight: '1.2em',
      },
      M: {
        fontSize: '0.75rem',
      },
    },
  },
  label: {
    fontFamily: "'Source Sans Pro', sans",
    fontWeight: 'bold',
    fontSize: '0.5625rem',
    opacity: 0.5,
    letterSpacing: '0.1em',
  } as CSSProperties,
  link: {} as CSSProperties,
  caption: {
    default: {
      fontFamily: "'Source Sans Pro' , 'Helvetica' , 'Arial' , sans",
      fontWeight: 500,
    } as CSSProperties,
    XL: {
      fontSize: '1rem',
    } as CSSProperties,
    L: {
      fontSize: '0.875rem',
    } as CSSProperties,
    M: {
      fontSize: '0.75rem',
    } as CSSProperties,
  },
  small: {
    fontSize: '0.75em',
  },
};

const colors = {
  background: '#FAF9FB',
  foreground: '#333',
  accent: '#D63B3B',
};

export const DefaultTheme = {
  dark: false,
  ...colors,

  transition: '250ms',

  media: {
    phone: `(max-width: ${defaultBreakPoints.phoneMaxWidth}px)`,
    tablet: `(min-width: ${defaultBreakPoints.phoneMaxWidth}px) and (max-width: ${defaultBreakPoints.tabletMaxWidth}px)`,
    desktop: `(min-width: ${defaultBreakPoints.tabletMaxWidth}px)`,
  },

  typography,

  elevation: ['none'],

  divider: {
    width: 1,
    opacity: 0.1,
  },

  reveal: {
    inactiveOpacity: 0.25,
    activeOpacity: 1,
  },
};

export type Theme = typeof DefaultTheme;
