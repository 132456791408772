import React, { useState } from 'react';
import {
  Root as PrimitiveRoot,
  CheckboxProps as Props,
  CheckedState,
} from '@radix-ui/react-checkbox';
import * as Icons from 'xyz-icon-set-react';
import { styled } from '../..';
import { Typography } from '..';

export interface CheckboxProps extends Props {
  children?: React.ReactNode;
}

export const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  children,
  checked: checkedOverride,
  ...props
}) => {
  const [checked, setChecked] = useState<CheckedState>(
    checkedOverride || false
  );

  return (
    <Root checked={checked} onCheckedChange={setChecked} {...props}>
      <Indicator>
        {checked === 'indeterminate' ? (
          <Icons.CheckboxIndeterminateThinIcon />
        ) : checked === true ? (
          <Icons.CheckboxCheckedThinIcon />
        ) : (
          <Icons.CheckboxUncheckedThinIcon />
        )}
      </Indicator>

      <Caption>
        <Typography.Caption size="L" textAlign="start">
          {children}
        </Typography.Caption>
      </Caption>
    </Root>
  );
};

export const Root = styled(PrimitiveRoot)`
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.foreground};
`;

export const Indicator = styled.div`
  font-size: 0;
  margin-right: 12px;
`;

export const Caption = styled.div`
  margin-top: 3px;
`;
