import React from 'react';
import { styledComponents as styled } from '../../../StyledComponents';

import {
  responsiveHelper,
  ResponsiveProps,
} from '../../../utils/responsiveHelper';

export interface TypographyProps extends ResponsiveProps<'textAlign'> {
  // textAlign?: React.CSSProperties['textAlign'];
  opacity?: React.CSSProperties['opacity'];
  textTransform?: React.CSSProperties['textTransform'];
  textDecoration?: React.CSSProperties['textDecoration'];
  fontWeight?: React.CSSProperties['fontWeight'];
  whiteSpace?: React.CSSProperties['whiteSpace'];
  lineHeight?: React.CSSProperties['lineHeight'];
  lineBreak?: React.CSSProperties['lineBreak'];
  fontStyle?: React.CSSProperties['fontStyle'];
}

export const typographyHelper = styled.css<TypographyProps>(
  ({
    opacity,
    textTransform,
    textDecoration,
    fontWeight,
    whiteSpace,
    lineHeight,
    lineBreak,
    fontStyle,
  }: TypographyProps) => {
    return styled.css`
      ${responsiveHelper('textAlign')};
      opacity: ${opacity};
      text-trasform: ${textTransform};
      text-decoration: ${textDecoration};
      font-weight: ${fontWeight};
      white-space: ${whiteSpace};
      line-height: ${lineHeight};
      line-break: ${lineBreak};
      font-style: ${fontStyle};

      sup {
        vertical-align: top;
        position: relative;
        top: -0.5em;
      }
    `;
  }
);
