import React, { useRef, useEffect, useState, useMemo } from 'react';
import { rgba } from 'polished';
import { useTheme } from 'styled-components';
import { styled } from '../../StyledComponents';
import { Theme } from '../../themes/DefaultTheme';
import {
  ResponsiveProps,
  responsiveHelper,
} from '../../utils/responsiveHelper';

const getRequestAnimationFrame = () => {
  if (typeof window !== 'undefined') {
    return (
      window.requestAnimationFrame ||
      (window as any).webkitRequestAnimationFrame ||
      (window as any).mozRequestAnimationFrame ||
      (window as any).msRequestAnimationFrame ||
      (window as any).oRequestAnimationFrame
    );
  } else {
    return () => {};
  }
};

const requestAnimationFrame = getRequestAnimationFrame();

export interface RevealProps extends ResponsiveProps<'display'> {
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
  /**
   * Size of the reveal mouse area
   *
   * Defaults to 400
   */
  size?: number;
  /**
   * Spread (or blur) of the reveal area. Accepts numbers from 0 to 1.
   *
   * If spread is 1, the corner of the reveal area will have no blur.
   *
   * Defaults to 0.5
   */
  spread?: number;
  /**
   * Opacity of the element when mouse is over the element
   *
   * Defaults to 1
   */
  activeOpacity?: number;
  /**
   * Opacity of the element when mouse is further away from the element
   *
   * Defaults to 0.25
   */
  inactiveOpacity?: number;
  /**
   * Delay of the effect fade out after the pointer stops moving
   * in milliseconds
   *
   * Defaults to 1000 milliseconds
   */
  fadeOutDelay?: number;
  /**
   * Duration of the fade out effect in milliseconds
   *
   * Defaults to 10000 milliseconds
   */
  fadeOutDuration?: number;
  /**
   * Duration of the fade in effect in milliseconds
   *
   * Defaults to 1000 milliseconds
   */
  fadeInDuration?: number;

  children?: React.ReactNode;
}

/**
 * TODO
 * - [x] Add fade out
 * - [ ] Add fade in
 */
export const Reveal: React.FC<React.PropsWithChildren<RevealProps>> = props => {
  const {
    children,
    className,
    innerRef,
    size = 560,
    spread: _spread = 0.5,
    activeOpacity: propActiveOpacity,
    inactiveOpacity: propInactiveOpacity,
    fadeOutDelay = 1000,
    fadeOutDuration = 10000,
    fadeInDuration = 1000,
  } = props;

  /**
   * Spread needs to be clamped between 1 and 0.01,
   * otherwise the animation will not work
   */
  const spread = useMemo(() => Math.max(Math.min(_spread, 1), 0.01), [_spread]);

  const theme = useTheme() as Theme;
  const [isTouching, setIsTouching] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const maskPosition = useRef<{ x: number; y: number }>();
  const ref = useRef<HTMLDivElement>(null);
  const timer = useRef<number>();

  const activeOpacity = propActiveOpacity || theme.reveal.activeOpacity;
  const inactiveOpacity = propInactiveOpacity || theme.reveal.inactiveOpacity;

  useEffect(() => {
    if (ref.current && innerRef) {
      innerRef.current = ref.current;
    }
  }, [ref]);

  const getFurthestDistanceFromScreenCorner = ({
    x,
    y,
  }: {
    x: number;
    y: number;
  }) => {
    const topLeft = [0, 0];
    const topRight = [window.innerWidth, 0];
    const bottomLeft = [0, window.innerHeight];
    const bottomRight = [window.innerWidth, window.innerHeight];

    const topLeftDistance = Math.sqrt(
      Math.pow(x - topLeft[0], 2) + Math.pow(y - topLeft[1], 2)
    );
    const topRightDistance = Math.sqrt(
      Math.pow(x - topRight[0], 2) + Math.pow(y - topRight[1], 2)
    );
    const bottomLeftDistance = Math.sqrt(
      Math.pow(x - bottomLeft[0], 2) + Math.pow(y - bottomLeft[1], 2)
    );
    const bottomRightDistance = Math.sqrt(
      Math.pow(x - bottomRight[0], 2) + Math.pow(y - bottomRight[1], 2)
    );

    return Math.max(
      topLeftDistance,
      topRightDistance,
      bottomLeftDistance,
      bottomRightDistance
    );
  };

  /**
   * Instantly removes the mask
   */
  const removeMask = () => {
    if (!ref.current) {
      return;
    }

    const element = ref.current as any;

    element.style.webkitMaskImage = '';
    element.style.maskImage = '';
  };

  const getMaskImage = ({
    x,
    y,
    size,
    spread,
  }: {
    x: number;
    y: number;
    size: number;
    spread: number;
  }) => {
    return `radial-gradient(circle at top ${y}px left ${x}px, ${rgba(
      0,
      0,
      0,
      activeOpacity
    )} ${size * spread}px, ${rgba(0, 0, 0, inactiveOpacity)} ${size}px)`;
  };

  const updateMaskImage = (maskImage: string) => {
    if (!ref.current) {
      return;
    }

    const element = ref.current as any;

    element.style.webkitMaskImage = maskImage;
    element.style.maskImage = maskImage;
  };

  const updateMask = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }) => {
    if (!ref.current) {
      return;
    }

    if (isTouching) {
      removeMask();
      return;
    }

    if (!isActive) {
      setIsActive(true);
    }

    const element = ref.current as any;
    const { x, y } = element.getBoundingClientRect();

    maskPosition.current = {
      x: clientX - x,
      y: clientY - y,
    };

    updateMaskImage(
      getMaskImage({
        x: maskPosition.current.x,
        y: maskPosition.current.y,
        size,
        spread,
      })
    );

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = (setTimeout(() => {
      if (isActive) {
        setIsActive(false);
      }

      animateMaskOut();
    }, fadeOutDelay) as unknown) as number;
  };

  const [isAnimating, setIsAnimating] = useState(false);
  const animationValue = useRef(0);
  const animatingValueTo = useRef<0 | 1>(1);
  const lastAnimationTime = useRef(0);
  const animationStartTime = useRef(0);

  /**
   * Animates the mask out by taking last pointer position
   * and animates that out
   */
  const animateMaskOut = () => {
    if (!ref.current || !maskPosition.current) {
      return;
    }

    animationStartTime.current = Date.now();
    lastAnimationTime.current = animationStartTime.current;

    animationValue.current = 0;

    setIsAnimating(true);

    /**
     * Animate from to to using requestAnimationFrame
     */
    const animate = () => {
      if (!maskPosition.current) {
        return;
      }

      const from = size;
      const toWithoutSpread = getFurthestDistanceFromScreenCorner(
        maskPosition.current
      );
      const to = toWithoutSpread * (1 / spread);

      const time = Date.now() - animationStartTime.current;
      const value = time / fadeOutDuration;

      const delta = Date.now() - lastAnimationTime.current;

      /**
       * 1 / duration * deltaTime
       */
      const animationValueDelta = (1 / fadeOutDuration) * delta;

      /** clamp animationValue */
      animationValue.current = Math.min(
        1,
        Math.max(0, animationValue.current + animationValueDelta)
      );

      /**
       * Calculate current size for this frame
       */
      const nextSize = from + (to - from) * animationValue.current;

      updateMaskImage(
        getMaskImage({
          x: maskPosition.current.x,
          y: maskPosition.current.y,
          size: nextSize,
          spread,
        })
      );

      if (
        (animatingValueTo.current === 1 && animationValue.current < 1) ||
        (animatingValueTo.current === 0 && animationValue.current > 0)
      ) {
        requestAnimationFrame(animate);
      } else {
        setIsAnimating(false);
      }
    };

    animate();
  };

  useEffect(() => {
    const mouseMoveCallback = (event: MouseEvent) => {
      if (isTouching) {
        setIsTouching(false);
      }

      updateMask(event);
    };

    const touchCallback = (event: TouchEvent) => {
      if (!isTouching) {
        setIsTouching(true);
      }

      /**
       * On touch devices this effect doesn't make much sense, therefore
       * the mask is removed on every touch (on devices with pointer &
       * touch streen this will still work until touch)
       */
      removeMask();
    };

    const wheelCallback = (event: WheelEvent) => {
      if (!isTouching) {
        updateMask(event);
      }
    };

    addEventListener('mousemove', mouseMoveCallback);
    addEventListener('touchmove', touchCallback);
    addEventListener('touchstart', touchCallback);
    addEventListener('wheel', wheelCallback);

    return () => {
      removeEventListener('mousemove', mouseMoveCallback);
      removeEventListener('touchmove', touchCallback);
      removeEventListener('touchstart', touchCallback);
      removeEventListener('wheel', wheelCallback);
    };
  }, []);

  return (
    <Root
      className={className}
      innerRef={ref}
      activeOpacity={activeOpacity}
      inactiveOpacity={inactiveOpacity}
    >
      {children}
    </Root>
  );
};

const RootComponent: React.FC<React.PropsWithChildren<RevealProps>> = props => {
  const { className, children, innerRef } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && innerRef) {
      innerRef.current = ref.current;
    }
  }, [ref]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};

const Root = styled(RootComponent)`
  ${responsiveHelper('display')};
  -webkit-mask-image: radial-gradient(
    circle at 0%,
    ${({ activeOpacity }) => rgba(0, 0, 0, activeOpacity)} 0%,
    ${({ activeOpacity }) => rgba(0, 0, 0, activeOpacity)} 100%
  );
  mask-image: radial-gradient(
    circle at 0%,
    ${({ activeOpacity }) => rgba(0, 0, 0, activeOpacity)} 0%,
    ${({ activeOpacity }) => rgba(0, 0, 0, activeOpacity)} 100%
  );
`;
