import { rgba } from 'polished';
import React, { HTMLAttributes, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '../../StyledComponents';
import { Typography } from '../Typography';

export interface ContextualPopupProps<T = any> {
  behaviour?: 'manual' | 'hover' | 'click';
  handle?: React.ReactNode;
  handleComponent?: React.FC<React.PropsWithChildren<T>>;
  handleProps?: React.PropsWithoutRef<T>;
  disabled?: boolean;
  open?: boolean;
}

export const ContextualPopup: React.FC<React.PropsWithChildren<
  ContextualPopupProps
>> = ({
  behaviour,
  handle,
  handleComponent: HandleComponent,
  handleProps,
  children,
  disabled = false,
  open: manualOpen = false,
  ...props
}) => {
  const portalElement =
    typeof document !== 'undefined' ? document.getElementById('portal') : null;
  const [open, setOpen] = useState(false);
  const handleRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [secondRender, setSecondRender] = useState(false);

  /**
   * Fix SSR issue by rendering modals only on the second render
   */
  useEffect(() => {
    setSecondRender(true);
  }, []);

  const setPopupOpen = (popupOpen: boolean) => {
    setOpen(popupOpen);

    const refresh = () => {
      const handle = handleRef.current;
      const popup = popupRef.current;

      if (!handle || !popup) return;

      const handleRect = handle.getClientRects()[0];
      const popupRect = popup.getClientRects()[0];

      const {
        scrollTop,
        clientWidth,
      } = document.scrollingElement as typeof document.body;

      const top = handleRect.top + handleRect.height + scrollTop + 8;
      const left = handleRect.left + handleRect.width / 2 - popupRect.width / 2;

      const minLeft = 16;
      const maxLeft = clientWidth - popupRect.width - 16;

      popup.style.top = `${top}px`;
      popup.style.left = `${
        left < minLeft ? minLeft : left > maxLeft ? maxLeft : left
      }px`;
    };

    if (popupOpen) {
      refresh();

      addEventListener('resize', refresh);
    } else {
      removeEventListener('resize', refresh);
    }
  };

  const onHandleClick: HTMLAttributes<HTMLDivElement>['onClick'] = event => {
    event.stopPropagation();

    setPopupOpen(!open);
  };
  const onPopupClick: HTMLAttributes<HTMLDivElement>['onClick'] = event => {
    event.stopPropagation();
  };

  const onHandleMouseOver = () => {
    setPopupOpen(true);
  };
  const onHandleMouseOut = () => {
    setPopupOpen(false);
  };

  const onBackdropClick = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <Handle
        ref={handleRef}
        onClick={behaviour === 'click' ? onHandleClick : undefined}
        onMouseOver={behaviour === 'hover' ? onHandleMouseOver : undefined}
        onMouseOut={behaviour === 'hover' ? onHandleMouseOut : undefined}
        {...handleProps}
      >
        {HandleComponent ? <HandleComponent /> : handle}
      </Handle>

      {portalElement &&
        secondRender &&
        ReactDOM.createPortal(
          <>
            {open && <PopupBackdrop onClick={onBackdropClick} />}

            <PopupRoot
              ref={popupRef}
              onClick={onPopupClick}
              open={behaviour === 'manual' ? manualOpen : open}
              {...props}
            >
              {children}
            </PopupRoot>
          </>,
          portalElement
        )}
    </>
  );
};

() => <ContextualPopup handleComponent={Typography.Paragraph} />;

const Handle = styled.span`
  display: inherit;
  position: relative;
  cursor: pointer;
  z-index: 10;
`;

const PopupBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: 9998;
`;

const PopupRoot = styled.div<{ open?: boolean }>`
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  background: rgba(255, 255, 255, 1);
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 320px;
  min-width: 280px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transform: translateY(${({ open }) => (open ? 0 : 8)}px);
  transition: transform 0.1s, opacity 0.1s;

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(32px);
  }
`;
