import React, { useMemo, AnchorHTMLAttributes } from 'react';

import { styled } from '../../StyledComponents';
import { TypographyProps, typographyHelper } from './utils/typographyHelper';
import {
  SpacingTypographyProps,
  spacingTypographyHelper,
} from './utils/spacingTypographyHelper';
import { Reveal, RevealProps } from '../Reveal';
import { useLocationHash } from './utils/useLocationHash';

export interface HeadlineProps
  extends CustomAnchorProps,
    TypographyProps,
    SpacingTypographyProps {
  id?: string;
  className?: string;
  anchor?: boolean | string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children?: React.ReactNode;
}

export const Headline: React.FC<React.PropsWithChildren<
  HeadlineProps
>> = props => {
  const {
    id,
    anchor = '',
    children = '',
    AnchorComponent,
    anchorRevealProps = {},
    ...rest
  } = props;

  const computedId = useMemo(() => {
    if (typeof children === 'string') {
      return children.toLowerCase().replace(/\s/g, '-');
    }
    return '';
  }, [children]);
  const isFocused = useLocationHash(computedId);

  return (
    <Root anchor={anchor} id={id || computedId} {...rest}>
      {anchor && (id || computedId) && (
        <Anchor
          AnchorComponent={AnchorComponent}
          anchorRevealProps={{
            inactiveOpacity: anchorRevealProps.inactiveOpacity || 0,
            size: anchorRevealProps.size || 240,
            spread: anchorRevealProps.spread || 0,
            ...anchorRevealProps,
          }}
          href={`#${id}`}
          focused={isFocused}
        >
          #
        </Anchor>
      )}
      {children}
    </Root>
  );
};

export interface RootComponentProps extends Omit<HeadlineProps, 'children'> {
  children?: React.ReactNode;
  id?: string;
}

const RootComponent: React.FC<React.PropsWithChildren<
  RootComponentProps
>> = props => {
  const { level, className, children, id } = props;

  return React.createElement(`h${level}`, { className, id }, children);
};

const RootWithTheme = styled(RootComponent)(
  ({
    theme,
    level,
    marginTop = theme.typography[`h${level}` as 'h1'].marginTop,
    marginBottom = theme.typography[`h${level}` as 'h1'].marginBottom,
  }) => ({
    ...theme.typography[`h${level}` as 'h1'],
    color: theme.foreground,
    marginTop,
    marginBottom,
  })
);

const Root = styled(RootWithTheme)`
  ${typographyHelper}
  ${spacingTypographyHelper}
  position: relative;
  scroll-margin-top: 4em;
`;

type AnchorRevealProps = RevealProps;
const AnchorRevealComponent: React.FC<React.PropsWithChildren<
  AnchorRevealProps
>> = props => <Reveal {...props} />;
const AnchorReveal = styled(AnchorRevealComponent)`
  position: absolute;
  left: -8px;
  transform: translateX(-100%);
  transition: ${({ theme }) => theme.transition};
`;

interface CustomAnchorProps {
  AnchorComponent?: React.ReactNode;
  anchorRevealProps?: AnchorRevealProps;
  focused?: boolean;
}
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & CustomAnchorProps;
const AnchorComponent: React.FC<React.PropsWithChildren<AnchorProps>> = ({
  AnchorComponent,
  anchorRevealProps,
  focused,
  children,
  ...props
}) => (
  <AnchorReveal {...anchorRevealProps}>
    <a {...props}>{AnchorComponent || children}</a>
  </AnchorReveal>
);
const Anchor = styled(AnchorComponent)`
  text-decoration: none;
  color: ${({ theme }) => theme.foreground};
  transition: ${({ theme }) => theme.transition};
  opacity: ${({ focused }) => (focused ? 1 : 0.1)};

  &:hover {
    opacity: 0.2;
  }
`;
