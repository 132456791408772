import { styledComponents } from '../../../StyledComponents';

export interface SpacingTypographyProps {
  marginTop?: 0;
  marginBottom?: 0;
}

export const spacingTypographyHelper = styledComponents.css<
  SpacingTypographyProps
>(({ marginTop, marginBottom }: SpacingTypographyProps) => {
  return styledComponents.css({
    marginTop,
    marginBottom,
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  });
});
