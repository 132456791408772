import React from 'react';
import { rgba } from 'polished';

import { RevealProps, Reveal } from '../Reveal/Reveal';
import { styled } from '../../StyledComponents';
import { MarginProps, marginHelper } from '../../utils/marginHelper';
import { PaddingProps, paddingHelper } from '../../utils/paddingHelper';
import { MaxWidthProps, maxWidthHelper } from '../../utils/maxWidthHelper';

export interface PaperProps
  extends RevealProps,
    MarginProps,
    PaddingProps,
    MaxWidthProps {
  variant?: 'outlined' | 'solid';
}

export const Paper: React.FC<React.PropsWithChildren<PaperProps>> = props => {
  const {
    children,
    className,
    activeOpacity = 1,
    inactiveOpacity = 1,
    variant = 'outlined',
  } = props;

  return (
    <Root className={className} {...props}>
      <RevealBorder
        variant={variant}
        activeOpacity={activeOpacity}
        inactiveOpacity={inactiveOpacity}
      />

      <Content {...props}>{children}</Content>
    </Root>
  );
};

const RootComponent: React.FC<React.PropsWithChildren<PaperProps>> = ({
  className,
  children,
}) => <div className={className}>{children}</div>;
const Root = styled(RootComponent)`
  ${marginHelper}
  ${maxWidthHelper}
  position: relative;
  background: ${({ theme, variant }) =>
    variant === 'solid' && rgba(theme.foreground, theme.dark ? 0.1 : 0.025)};
  border-radius: 8px;
  backdrop-filter: ${({ variant }) => variant === 'solid' && 'blur(16px)'};
`;

const RevealBorder = styled(Reveal)<{ variant: any }>`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${({ theme, variant }) =>
      variant === 'solid' ? 0 : theme.divider.width}px
    solid ${({ theme }) => rgba(theme.foreground, theme.divider.opacity)};
  transition: ${({ theme }) => theme.transition};
  border-radius: 8px;
  backdrop-filter: blur(${({ variant }) => (variant === 'solid' ? 40 : 0)}px);
`;

const ContentComponent: React.FC<React.PropsWithChildren<PaperProps>> = ({
  className,
  children,
}) => <div className={className}>{children}</div>;
const Content = styled(ContentComponent)`
  ${paddingHelper}
  position: relative;
  z-index: 1;
`;
