import React from 'react';
import { styled } from '../../StyledComponents';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { Reveal } from '../Reveal';
import { Typography } from '../Typography';

export interface ContextualPopupHeadingProps {
  children: string;
  className?: string;
}

export const ContextualPopupHeading: React.FC<React.PropsWithChildren<ContextualPopupHeadingProps>> = props => {
  const { className, children } = props;

  return (
    <>
      <Box padding={8}>
        <Reveal inactiveOpacity={0.5} activeOpacity={0.75}>
          <Typography.Caption size="L" textAlign="center" className={className}>
            {children}
          </Typography.Caption>
        </Reveal>
      </Box>
      <Divider.Horizontal />
    </>
  );
};

const HeaderRoot = styled.div``;
