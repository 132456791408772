import React from 'react';
import { ChevronRightIcon, BartaxyzIcon } from 'xyz-icon-set-react';
import { rgba } from 'polished';
import { keyframes } from 'styled-components';

import { styled } from '../..';
import { Reveal } from '../Reveal';

interface Item {
  label: string;
  href?: string;
}

export interface PageBreadcrumbHeaderProps {
  items: Item[];
}

export const PAGE_BREADCRUMB_HEADER_MIN_HEIGHT = 56;

export const PageBreadcrumbHeader: React.FC<React.PropsWithChildren<PageBreadcrumbHeaderProps>> = props => {
  const { items } = props;
  return (
    <React.Fragment>
      <Root>
        <Breadcrumb>
          <Item>
            <Logo />

            <Link href="/">
              <Label>Ondřej Bárta</Label>
            </Link>
          </Item>

          {items.map((item, index) => (
            <Item key={index}>
              <Chevron>
                <ChevronRightIcon />
              </Chevron>

              <Link href={item.href}>
                <Label>{item.label}</Label>
              </Link>
            </Item>
          ))}
        </Breadcrumb>

        <BreadcrumbDivider />
      </Root>

      {/* Visual placeholder for the height of the Root */}
      <RootMirror />
    </React.Fragment>
  );
};

const Root = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: ${PAGE_BREADCRUMB_HEADER_MIN_HEIGHT}px;
  padding: 16px 24px;
  color: ${({ theme }) => theme.foreground};
  transition: ${({ theme }) => theme.transition};
  z-index: 10;

  @media ${({ theme }) => theme.media.phone} {
    position: relative;
    justify-content: flex-start;
  }
`;

const RootMirror = styled.div`
  /* min-height: 56px; */

  @media ${({ theme }) => theme.media.phone} {
    display: none;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BreadcrumbDivider = styled(Reveal)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${({ theme }) => theme.divider.width}px;
  width: 100%;
  background: ${({ theme }) => rgba(theme.foreground, theme.divider.opacity)};
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.media.phone} {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  svg {
    margin-right: 8px;
  }
`;

/* horizontal spin animation */
const LogoAnimation = keyframes`
  80% {
    transform: rotateY(0deg);
  }
  90% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const Logo = styled(BartaxyzIcon)`
  animation: ${LogoAnimation} 10s linear infinite;
`;

const Chevron = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  margin: 1px 8px 0;

  @media ${({ theme }) => theme.media.phone} {
    margin: 4px 8px 0 12px;
    align-self: flex-start;
  }

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
    zoom: 0.5;

    @media ${({ theme }) => theme.media.phone} {
      transform: rotateZ(135deg);
    }
  }
`;

const Link = styled.a`
  margin: -4px -8px;
  padding: 4px 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.foreground};
`;

const Label = styled.span`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 14px;
  color: currentColor;
`;
