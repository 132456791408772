import React from 'react';
import { styled } from '../../StyledComponents';
import { Box, BoxProps } from '../Box';

export interface ContextualPopupSectionProps extends BoxProps {}

export const ContextualPopupSection: React.FC<React.PropsWithChildren<ContextualPopupSectionProps>> = ({
  children,
  ...props
}) => (
  <Root padding={16} {...props}>
    {children}
  </Root>
);

const Root = styled(Box)`
  overflow: auto;
`;
