import React from 'react';
import { rgba } from 'polished';

import { styled } from '../..';
import { Typography } from '../Typography';
import { Divider } from '../Divider';

export interface TableListProps {
  header?: React.ReactNode;
  items: React.ReactNodeArray;
}

export const TableList: React.FC<React.PropsWithChildren<TableListProps>> = props => {
  const { header, items } = props;
  return (
    <Root>
      {header && (
        <>
          <Row isHeader={true}>
            <Typography.Caption textAlign="center">{header}</Typography.Caption>
          </Row>

          {items.length !== 0 && <RowDivider />}
        </>
      )}

      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Row>
            <Typography.Caption textAlign="center">{item}</Typography.Caption>
          </Row>

          {items.length - 1 !== index && <RowDivider />}
        </React.Fragment>
      ))}
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Row = styled.div<{ isHeader?: boolean; isLast?: boolean }>`
  padding: 8px;
  opacity: ${({ isHeader }) => (isHeader ? 1 : 0.5)};
`;

const RowDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => rgba(theme.foreground, 0.1)};
`;
