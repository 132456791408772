import { CSSProperties } from 'react';

import { styledComponents } from '../StyledComponents';
import { ResponsiveProperty, getResponsiveProperty } from './responsive';

export type DisplayType = ResponsiveProperty<CSSProperties['display']>;

export interface DisplayProps {
  display?: DisplayType;
}

export const displayHelper = styledComponents.css<DisplayProps>(
  ({ theme, display, ...props }) => {
    return styledComponents.css`
      ${getResponsiveProperty('display', display, theme)}
    `;
  }
);
