import { styled } from '../../StyledComponents';

export const BreadcrumbItem = styled.div`
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.media.phone} {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  svg {
    margin-right: 8px;
  }
`;
